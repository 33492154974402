import { CardContent, Divider, List } from "@mui/material";
import { differenceInHours, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import { SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH } from "../../paths";
import { useWorkplaceModalsDataContext } from "../../useWorkplaceModalsDataContext";
import { ShiftQualificationPill } from "../QualificationPill";
import { type ShiftWithType } from "../types";
import { ShiftBottomSheetDateTimeInfoListItem } from "./DateTimeInfoListItem";
import { ShiftBottomSheetFacilityInfoListItem } from "./FacilityInfoListItem";
import { ShiftBottomSheetInnerCard } from "./InnerCard";
import { ShiftBottomSheetPayInfoListItem } from "./PayInfoListItem";

interface ShiftBottomSheetSummaryCardProps {
  shift: ShiftWithType;
}
export function ShiftBottomSheetSummaryCard(props: ShiftBottomSheetSummaryCardProps) {
  const { shift } = props;
  const {
    agentReq,
    name: timeSlot,
    start,
    end,
    offer,
    finalPay,
    originalAmount,
    facility,
    isHCFInstantPayProhibited,
    isInstantPay,
    time: durationInHours,
  } = shift;
  const { name: workplaceName, userId: workplaceId = "", geoLocation } = facility;

  const { setWorkplace: setFacility, generateModalPath } = useWorkplaceModalsDataContext();

  const startDate = parseISO(start);
  const endDate = parseISO(end);

  const history = useHistory();

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent
        sx={{
          padding: 6,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <ShiftQualificationPill qualificationName={agentReq} />

        <List>
          <ShiftBottomSheetDateTimeInfoListItem
            timeSlot={timeSlot}
            startDate={startDate}
            endDate={endDate}
            durationInHours={durationInHours ?? differenceInHours(endDate, startDate)}
          />

          <Divider />

          <ShiftBottomSheetPayInfoListItem
            offer={offer}
            finalPay={finalPay}
            originalAmount={originalAmount}
            isShiftInstantPayEligible={isInstantPay}
            isHCFInstantPayProhibited={isHCFInstantPayProhibited}
          />

          <Divider />

          <ShiftBottomSheetFacilityInfoListItem
            facilityName={workplaceName}
            facilityGeoLocation={geoLocation}
            onClick={() => {
              setFacility(facility);

              history.push(
                generateModalPath(SHIFT_DISCOVERY_WORKPLACE_PROFILE_MODAL_PATH, {
                  workplaceId,
                })
              );
            }}
          />
        </List>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
