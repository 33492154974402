import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";

import { type ShiftWithType } from "../types";
import { ShiftBottomSheetFooter } from "./Footer";
import { ShiftBottomSheetContent } from "./SheetContent";
import { ShiftBottomSheetSkeleton } from "./SheetSkeleton";
import { ShiftBottomSheetWrapper, type ShiftBottomSheetWrapperProps } from "./Wrapper";

interface ShiftBottomSheetProps extends Pick<ShiftBottomSheetWrapperProps, "modalState"> {
  shift?: ShiftWithType;
  isLoading: boolean;
  isError: boolean;
  bookabilityStatus: BookabilityStatusItem;
}

/**
 * A bottom sheet container for displaying shift details.
 *
 * The shift data can come from two sources:
 * 1. Directly via props when opened from a shift card
 * 2. Fetched from the API using the shift ID when:
 *    - Navigating between workplace views and browsing different shifts
 *    - Using back button in workplace modals going back to various shifts visited earlier
 *    - Opening a shift directly via URL
 */
export function ShiftBottomSheet(props: ShiftBottomSheetProps) {
  const { shift, modalState, isLoading, isError, bookabilityStatus } = props;
  const { name: shiftTimeSlot, type: shiftType } = shift ?? {};

  return (
    <ShiftBottomSheetWrapper
      shiftType={shiftType}
      shiftTimeSlot={shiftTimeSlot}
      modalState={modalState}
      footer={
        <ShiftBottomSheetFooter
          shift={shift}
          isLoading={isLoading}
          modalState={modalState}
          bookabilityStatus={bookabilityStatus}
        />
      }
    >
      {isDefined(shift) && isDefined(bookabilityStatus) && !isLoading && (
        <ShiftBottomSheetContent shift={shift} bookabilityStatus={bookabilityStatus} />
      )}

      {isLoading && <ShiftBottomSheetSkeleton />}

      {isError && (
        // TODO: Add proper error state
        <Text
          variant="h3"
          sx={(theme) => ({
            textAlign: "center",
            padding: 10,
            color: theme.palette.text.destructive,
          })}
        >
          Could not load shift details
        </Text>
      )}
    </ShiftBottomSheetWrapper>
  );
}
