import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { isFuture, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";

import { formatDateRangeDurationCompact } from "../../utils/formatDateRangeDurationCompact";
import { formatDayOfMonthWithTime } from "../../utils/formatDayOfMonthWithTime";
import { ShiftType } from "../constants";

interface ShiftBottomSheetTitleProps {
  shiftType: ShiftType;
  priorityTillDatetimeString?: Shift["priorityTill"];
  endDatetimeString?: Shift["end"];
  startDatetimeString?: Shift["start"];
}

export function ShiftBottomSheetTitleGenericContent(props: ShiftBottomSheetTitleProps) {
  const { shiftType, priorityTillDatetimeString, endDatetimeString, startDatetimeString } = props;

  const [countdownValue, setCountdownValue] = useState<string | undefined>();
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!startDatetimeString) {
      return undefined;
    }

    let countdownDate: Date;

    /**
     * Urgent shift label shows up time left to start or time left to end if the shift has already started
     * For the latter case there is no need for countdown as we show the end datetime
     */
    if (shiftType === ShiftType.URGENT) {
      const startDate = parseISO(startDatetimeString);

      if (!isFuture(startDate)) {
        return undefined;
      }

      countdownDate = startDate;
    } else if (shiftType === ShiftType.PRIORITY) {
      /**
       * Priority shift (if it's not A-Team) shows up time left to book the shift within priority access period
       */
      countdownDate = parseISO(priorityTillDatetimeString ?? startDatetimeString);
    } else {
      /**
       * A-Team shift does not show any countdown
       */
      return undefined;
    }

    const calculateCountdown = () => {
      const distance = formatDateRangeDurationCompact({
        startDate: new Date(),
        endDate: countdownDate,
      });

      setCountdownValue(distance);
    };

    calculateCountdown();

    intervalRef.current = setInterval(() => {
      calculateCountdown();
    }, 5000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [priorityTillDatetimeString, startDatetimeString, shiftType]);

  if (!isDefined(startDatetimeString)) {
    return <>Confirm booking</>;
  }

  if (shiftType === ShiftType.URGENT) {
    return countdownValue ? (
      <>
        This urgent shift starts in{" "}
        <Box component="span" sx={(theme) => ({ color: theme.palette.shifts?.urgent.text })}>
          {countdownValue}
        </Box>
      </>
    ) : (
      <>
        Leave now and work until{" "}
        <Box component="span" sx={(theme) => ({ color: theme.palette.shifts?.urgent.text })}>
          {endDatetimeString && formatDayOfMonthWithTime(parseISO(endDatetimeString))}
        </Box>
      </>
    );
  }

  if (shiftType === ShiftType.PRIORITY) {
    if (!priorityTillDatetimeString) {
      return <>A Team access - book now before it ends!</>;
    }

    if (countdownValue) {
      return (
        <>
          <Box
            component="span"
            sx={(theme) => ({ color: theme.palette.shifts?.priority.labelDark })}
          >
            {countdownValue}
          </Box>{" "}
          left to book this priority shift
        </>
      );
    }
  }

  return <>Confirm booking</>;
}
