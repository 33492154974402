import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { BookabilityDecision, type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";

import { DialogFooter } from "../../components/DialogFooter";
import { SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH } from "../../paths";
import { useShiftModalsDataContext } from "../../useShiftModalsDataContext";
import { useShiftDiscoverySearchMode } from "../../utils/useShiftDiscoverySearchMode";
import { type ShiftWithType } from "../types";
import { BookShiftButton } from "./BookShiftButton";
import { FulfillBookabilityCriteriaButton } from "./FulfillBookabilityCriteriaButton";

interface ShiftBottomSheetSheetFooterProps {
  isLoading: boolean;
  shift?: ShiftWithType;
  modalState: UseModalState;
  bookabilityStatus: BookabilityStatusItem;
}

export function ShiftBottomSheetFooter(props: ShiftBottomSheetSheetFooterProps) {
  const { shift, modalState, bookabilityStatus, isLoading } = props;
  const { _id: shiftId } = shift ?? {};

  const searchMode = useShiftDiscoverySearchMode();
  const { navigateToModal } = useShiftModalsDataContext();

  const isBookable =
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED;

  return (
    <DialogFooter
      orientation="vertical"
      onClose={() => {
        modalState.closeModal();
      }}
    >
      {!isBookable && (
        <FulfillBookabilityCriteriaButton
          isLoading={isLoading}
          bookabilityStatus={bookabilityStatus}
          shiftId={String(shiftId)}
        />
      )}

      {isDefined(shift) && isBookable && (
        <BookShiftButton
          shift={shift}
          searchMode={searchMode}
          onBook={() => {
            navigateToModal(SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH, {
              shiftId: String(shiftId),
            });
          }}
        />
      )}
    </DialogFooter>
  );
}
