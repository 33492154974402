import { Text } from "@clipboard-health/ui-react";
import { Box, CardContent, Stack } from "@mui/material";
import type { Facility } from "@src/appV2/Facilities/types";

import { Button } from "../../components/Button";
import { SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH } from "../../paths";
import { useWorkplaceModalsDataContext } from "../../useWorkplaceModalsDataContext";
import { WorkplaceHighlightPills } from "../../Workplace/Highlights/Pills";
import { useWorkplacePolicyHighlights } from "../../Workplace/Highlights/useWorkplacePolicyHighlights";
import { useWorkplaceReviewHighlights } from "../../Workplace/Highlights/useWorkplaceReviewHighlights";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetWorkplaceHighlightsCardProps {
  workplace: Facility;
}

export function ShiftBottomSheetWorkplaceHighlightsCard(
  props: ShiftBottomSheetWorkplaceHighlightsCardProps
) {
  const { workplace } = props;
  const { userId: workplaceId, lateCancellation, requiresLunchBreak, name } = workplace;

  const { highlights: policyHighlights, isLoading: isPolicyHighlightsLoading } =
    useWorkplacePolicyHighlights({
      workplaceId,
      lateCancellation,
      requiresLunchBreak,
    });

  const { highlights: reviewHighlights, isLoading: isReviewHighlightsLoading } =
    useWorkplaceReviewHighlights({ workplaceId });

  const { generateModalPath, setWorkplace } = useWorkplaceModalsDataContext();

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent sx={{ padding: 6, height: "100%" }}>
        <Stack sx={{ height: "100%", gap: 7 }}>
          <Text semibold variant="body1">
            {name} Highlights
          </Text>

          <Box marginTop="auto" overflow="auto">
            <WorkplaceHighlightPills
              highlights={[...reviewHighlights, ...policyHighlights]}
              isLoading={isReviewHighlightsLoading || isPolicyHighlightsLoading}
            />
          </Box>

          <Button
            fullWidth
            variant="outlined"
            size="medium"
            href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_POLICIES_MODAL_PATH, {
              workplaceId: String(workplaceId),
            })}
            onClick={() => {
              setWorkplace(workplace);
            }}
          >
            Read Policies
          </Button>
        </Stack>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
