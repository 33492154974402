import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type Theme } from "@mui/material";

import { BottomSheet, type BottomSheetProps } from "../../components/BottomSheet";
import { ShiftType } from "../constants";
import { TimeSlot } from "../OpenShifts/types";

export interface ShiftBottomSheetWrapperProps extends BottomSheetProps {
  shiftType?: ShiftType;
  shiftTimeSlot?: TimeSlot;
}

function resolveBackground(theme: Theme, shiftType?: ShiftType, shiftTimeSlot?: TimeSlot) {
  // returning day gradient as default as it will be used when
  // e.g shift is still loading and it looks way better than just white card
  const defaultGradient = theme.palette.shifts?.day.gradient;

  if (!isDefined(shiftType) || !isDefined(shiftTimeSlot)) {
    return defaultGradient;
  }

  switch (shiftType) {
    case ShiftType.REGULAR: {
      if (shiftTimeSlot === TimeSlot.AM) {
        return theme.palette.shifts?.day.gradient;
      }

      if (shiftTimeSlot === TimeSlot.PM) {
        return theme.palette.shifts?.evening.gradient;
      }

      if (shiftTimeSlot === TimeSlot.NOC) {
        return theme.palette.shifts?.overnight.gradient;
      }

      return defaultGradient;
    }

    case ShiftType.URGENT: {
      return theme.palette.shifts?.urgent.gradient;
    }

    case ShiftType.PRIORITY: {
      return theme.palette.shifts?.priority.gradient;
    }

    default: {
      return defaultGradient;
    }
  }
}

export function ShiftBottomSheetWrapper(props: ShiftBottomSheetWrapperProps) {
  const { shiftType, shiftTimeSlot, paperSx, ...restProps } = props;

  return (
    <BottomSheet
      {...restProps}
      paperSx={mergeSxProps(
        (theme) => ({ background: resolveBackground(theme, shiftType, shiftTimeSlot) }),
        paperSx
      )}
    />
  );
}
