import { useGetMissingRequirements } from "@src/appV2/Accounts/Documents/api/useGetMissingRequirements";
import {
  type BookabilityStatusItem,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { HorizontalScrollView } from "../../components/HorizontalScrollView";
import { type ShiftWithType } from "../types";
import { ShiftBottomSheetCancellationPoliciesCard } from "./CancellationPoliciesCard";
import { ShiftBottomSheetFacilityMapLocationCard } from "./FacilityMapLocationCard";
import { ShiftBottomSheetFacilityPhotosCard } from "./FacilityPhotosCard";
import { ShiftBottomSheetMissingDocumentsCard } from "./MissingDocumentsCard";
import { ShiftBottomSheetQuizCard } from "./QuizCard";
import { ShiftBottomSheetSummaryCard } from "./SummaryCard";
import { ShiftBottomSheetTitle } from "./Title";
import { ShiftBottomSheetWorkplaceHighlightsCard } from "./WorkplaceHighlightsCard";
import { ShiftBottomSheetWorkplaceReviewsCard } from "./WorkplaceReviewsCard";

interface ShiftBottomSheetSheetContentProps {
  shift: ShiftWithType;
  bookabilityStatus: BookabilityStatusItem;
}

export function ShiftBottomSheetContent(props: ShiftBottomSheetSheetContentProps) {
  const { shift, bookabilityStatus } = props;
  const { facility: workplace, type, priorityTill, start, end } = shift;

  const { userId: workplaceId, lateCancellation, sentHomeChargeHours, geoLocation } = workplace;

  const { period: lateCancellationPeriod, feeHours: lateCancellationFeeHours } =
    lateCancellation ?? {};

  const { userId: workerId } = useDefinedWorker();

  const hasMissingRequirements = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS,
    BookabilityUnmetCriteria.WORKER_MISSING_DOCUMENTS,
  ]);

  const hasQuizToPass = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
  ]);

  const { missingDocuments: missingDocumentsSet } = useGetMissingRequirements(
    {
      workerId,
      facility: workplace,
    },
    {
      enabled: hasMissingRequirements,
    }
  );

  return (
    <>
      <ShiftBottomSheetTitle
        shiftType={type}
        hasMissingDocuments={missingDocumentsSet?.size > 0}
        priorityTillDatetimeString={priorityTill}
        endDatetimeString={end}
        startDatetimeString={start}
        sx={{ padding: 7, textWrap: "balance" }}
      />

      <HorizontalScrollView stretch sx={{ px: 5 }}>
        {hasMissingRequirements && (
          <ShiftBottomSheetMissingDocumentsCard missingDocumentsCount={missingDocumentsSet?.size} />
        )}
        {hasQuizToPass && <ShiftBottomSheetQuizCard />}
        <ShiftBottomSheetSummaryCard shift={shift} />
        <ShiftBottomSheetCancellationPoliciesCard
          lateCancellationPeriod={lateCancellationPeriod ?? 0}
          lateCancellationFeeHours={lateCancellationFeeHours ?? 0}
          sentHomeChargeHours={sentHomeChargeHours ?? 0}
          workplaceId={String(workplaceId)}
          workplace={workplace}
        />
        <ShiftBottomSheetFacilityPhotosCard facilityId={String(workplaceId)} />
        <ShiftBottomSheetWorkplaceHighlightsCard workplace={workplace} />
        <ShiftBottomSheetWorkplaceReviewsCard workplace={workplace} />
        <ShiftBottomSheetFacilityMapLocationCard facilityLocation={geoLocation} />
      </HorizontalScrollView>
    </>
  );
}
