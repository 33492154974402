import { ACCOUNTS_DOCUMENTS_FULL_PATH } from "@src/appV2/Accounts/Documents/paths";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction/types";
import { useHistory } from "react-router-dom";

import { LoadingButton } from "../../components/LoadingButton";
import { SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH } from "../../paths";
import { useShiftModalsDataContext } from "../../useShiftModalsDataContext";

interface FulfillBookabilityCriteriaButtonProps {
  bookabilityStatus: BookabilityStatusItem;
  shiftId: string;
  isLoading: boolean;
}

export function FulfillBookabilityCriteriaButton(props: FulfillBookabilityCriteriaButtonProps) {
  const { bookabilityStatus, shiftId, isLoading } = props;

  const history = useHistory();
  const { navigateToModal } = useShiftModalsDataContext();

  const hasQuizToPass = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
  ]);

  const hasMissingRequirements = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_MISSING_DOCUMENTS,
    BookabilityUnmetCriteria.WORKER_MISSING_REQUIREMENTS,
  ]);

  const getLabel = () => {
    if (hasQuizToPass) {
      return "Start Quiz (3 min)";
    }

    if (hasMissingRequirements) {
      return "View Requirements";
    }

    return "Can't book this shift";
  };

  const onClick = () => {
    if (hasQuizToPass) {
      navigateToModal(SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH, {
        shiftId,
      });

      return;
    }

    if (hasMissingRequirements) {
      history.push(ACCOUNTS_DOCUMENTS_FULL_PATH, {
        // this account page has a logic for back button that goes back to
        // location.state.returnUrl if provided so we're using that
        returnUrl: history.location.pathname,
      });
    }
  };

  return (
    <LoadingButton
      fullWidth
      variant="contained"
      size="large"
      isLoading={isLoading}
      onClick={onClick}
    >
      {getLabel()}
    </LoadingButton>
  );
}
