import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";
import pluralize from "pluralize";

import { Illustration } from "../../components/Illustration";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

interface ShiftBottomSheetMissingDocumentsCardProps {
  missingDocumentsCount: number;
}

export function ShiftBottomSheetMissingDocumentsCard(
  props: ShiftBottomSheetMissingDocumentsCardProps
) {
  const { missingDocumentsCount } = props;

  const documentText = pluralize("document", missingDocumentsCount);
  const requirementText = missingDocumentsCount === 1 ? "this requirement" : "these requirements";

  return (
    <ShiftBottomSheetInnerCard>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 10,
        }}
      >
        <Stack alignItems="center" spacing={5} sx={{ textAlign: "center", textWrap: "balance" }}>
          <Illustration type="document-upload" />
          <Text variant="h5">
            This facility requires {missingDocumentsCount} {documentText}
          </Text>
          <Text color={(theme) => theme.palette.text.secondary} variant="body2">
            Please upload {requirementText} before booking this shift.
          </Text>
        </Stack>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
