import { CardContent, Skeleton } from "@mui/material";

import { HorizontalScrollView } from "../../components/HorizontalScrollView";
import { PillSkeleton } from "../../components/PillSkeleton";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

export function ShiftBottomSheetSkeleton() {
  return (
    <>
      <Skeleton
        variant="text"
        width="50%"
        sx={(theme) => ({
          margin: 7,
          fontSize: theme.typography.h3.fontSize,
          lineHeight: theme.typography.h3.lineHeight,
        })}
      />

      <HorizontalScrollView stretch sx={{ px: 5, pointerEvents: "none" }}>
        <ShiftBottomSheetInnerCard>
          <CardContent
            sx={{
              padding: 6,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 7,
            }}
          >
            <PillSkeleton />
            {/* TODO: This needs List skeleton which will be introduced as follow up */}
            <Skeleton variant="text" width="70%" height="25%" />
            <Skeleton variant="text" width="85%" height="25%" />
            <Skeleton variant="text" width="65%" height="25%" />
          </CardContent>
        </ShiftBottomSheetInnerCard>

        <ShiftBottomSheetInnerCard>
          <CardContent
            sx={{
              padding: 6,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 7,
            }}
          >
            <PillSkeleton />
            <Skeleton variant="text" width="60%" height="25%" />
            <Skeleton variant="text" width="60%" height="25%" />
            <Skeleton variant="text" width="60%" height="25%" />
          </CardContent>
        </ShiftBottomSheetInnerCard>
      </HorizontalScrollView>
    </>
  );
}
