import { mergeSxProps, Title } from "@clipboard-health/ui-react";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { type ComponentProps } from "react";

import { type ShiftType } from "../constants";
import { ShiftBottomSheetTitleGenericContent } from "./TitleGenericContent";

type TitleProps = ComponentProps<typeof Title>;
type TitleVariant = TitleProps["variant"];
type TitleComponent = TitleProps["component"];

interface ShiftBottomSheetTitleProps extends Omit<TitleProps, "component" | "variant"> {
  shiftType: ShiftType;
  hasMissingDocuments?: boolean;
  hasQuizToPass?: boolean;
  priorityTillDatetimeString?: Shift["priorityTill"];
  endDatetimeString?: Shift["end"];
  startDatetimeString?: Shift["start"];
  component?: TitleComponent;
  variant?: TitleVariant;
}

export function ShiftBottomSheetTitle(props: ShiftBottomSheetTitleProps) {
  const {
    shiftType,
    hasMissingDocuments,
    hasQuizToPass,
    priorityTillDatetimeString,
    endDatetimeString,
    startDatetimeString,
    component = "h3",
    variant = "h3",
    sx,
    ...restProps
  } = props;

  const titleProps = { component, variant, ...restProps };

  function getContent() {
    if (hasMissingDocuments) {
      return "Upload your documents to book this shift";
    }

    if (hasQuizToPass) {
      return "Please take a short quiz to book this shift";
    }

    return (
      <ShiftBottomSheetTitleGenericContent
        shiftType={shiftType}
        priorityTillDatetimeString={priorityTillDatetimeString}
        endDatetimeString={endDatetimeString}
        startDatetimeString={startDatetimeString}
      />
    );
  }

  return (
    <Title {...titleProps} sx={mergeSxProps({ padding: 7, textWrap: "balance" }, sx)}>
      {getContent()}
    </Title>
  );
}
