import { Text } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";

import { Illustration } from "../../components/Illustration";
import { ShiftBottomSheetInnerCard } from "./InnerCard";

export function ShiftBottomSheetQuizCard() {
  return (
    <ShiftBottomSheetInnerCard>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 10,
        }}
      >
        <Stack alignItems="center" spacing={5} sx={{ textAlign: "center", textWrap: "balance" }}>
          <Illustration type="quiz-required" />
          <Text semibold variant="h5" sx={{ textWrap: "balance" }}>
            This workplace&apos;s rules have changed.
          </Text>

          <Text color={(theme) => theme.palette.text.secondary} variant="body2">
            Please take a quiz to demonstrate your understanding of these new rules before you can
            book this shift
          </Text>
        </Stack>
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
