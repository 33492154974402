import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { BottomSheet } from "@src/appV2/ShiftDiscovery/components/BottomSheet";
import { DialogFooter } from "@src/appV2/ShiftDiscovery/components/DialogFooter";
import { usePostPolicyAcknowledgement } from "@src/appV2/Shifts/MandatoryBreakPolicy/api/usePostPolicyAcknowledgement";
import { NoteAcknowledgementAction } from "@src/appV2/Shifts/MandatoryBreakPolicy/types";
import { matchPath, useLocation } from "react-router-dom";

import { Illustration } from "../../components/Illustration";
import { LoadingButton } from "../../components/LoadingButton";
import {
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
} from "../../paths";
import { type ShiftBreakModalRoutePathParams } from "../../types";
import { useShiftModalsDataContext } from "../../useShiftModalsDataContext";
import type { OpenShift } from "../OpenShifts/types";
import { type ShiftWithType } from "../types";

interface BreakConfirmationBottomSheetProps {
  shift: ShiftWithType;
  openShift?: OpenShift;
  modalState: UseModalState;
}

export function BreakConfirmationBottomSheet(props: BreakConfirmationBottomSheetProps) {
  const { modalState, shift, openShift } = props;
  const { navigateToModal, baseNavigationPath } = useShiftModalsDataContext();
  const { mutateAsync: postBreakPolicyAcknowledgement, isLoading } = usePostPolicyAcknowledgement();
  const { pathname } = useLocation();
  const match = matchPath<ShiftBreakModalRoutePathParams>(pathname, {
    path: [`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`],
    exact: true,
    strict: false,
  });

  const noteId = match?.params.noteId ?? "";

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <LoadingButton
            fullWidth
            isLoading={isLoading}
            size="large"
            variant="contained"
            onClick={async () => {
              await postBreakPolicyAcknowledgement({
                policyAcknowledgementAction: NoteAcknowledgementAction.BOOK_SHIFT,
                noteId,
              });
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, {
                shiftId: String(shift?._id ?? openShift?.id),
              });
            }}
          >
            Continue with booking
          </LoadingButton>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack direction="column" alignItems="center" spacing={8}>
          <Illustration type="break" />
          <Title variant="h3" component="h3" sx={{ textAlign: "center" }}>
            This facility requires a 30-minute unpaid break
          </Title>
          <Text color={(theme) => theme.palette.text.secondary} variant="body1">
            The only circumstances under which working through a break is allowed at this workplace
            is if workplace staff instructs you to work through the break, or if no workplace staff
            was present during the shift to instruct you to work through but the workload made it
            unavoidable to work through the break.
          </Text>
          <Text color={(theme) => theme.palette.text.secondary} variant="body1">
            If you work through the break, you will have to submit additional documentation. The
            payment for the additional worked time may be withheld for up to 3 business days while
            the break payment request undergoes a separate approval process.
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
